import { ethers } from "ethers";
import StakingABI from "./StakingABI.json";
import abi from "./MainContractAbi.json";

import {
  Box,
  Flex,
  Text,
  Image,
  Container,
  Heading,
  Button,
} from "@chakra-ui/react";
import ColLogo from "./assets/mainContent/ColLogo.png";
import "./App.css";
import NavBarMint from "./NavBarMint.jsx";
import SwordsIcon from "./lib/swords";
import React, { useState, useEffect } from 'react';
import { set } from "@firebase/database";

const fs = require("fs");

const MainAddress = "0x08244aC887bb5d8d689315ce6335D742350133E6";
const StakingAddress = "0xF1c7879262C274b5670686359740aC54a30Be8B7"

const MintPage = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);
  const [balance, setBalance] = useState(0);
  const [stakedBalance, setStakedBalance] = useState(0);
  const [stakedBalance1Month, setStakedBalance1Month] = useState(0);
  const [stakedBalance3Months, setStakedBalance3Months] = useState(0);
  const [stakedBalance1Year, setStakedBalance1Year] = useState(0);
  const [stakedBalance3Years, setStakedBalance3Years] = useState(0);
  const [canStakeAgain, setCanStakeAgain] = useState(false);

  const [stakingDuration, setStakingDuration] = useState('3'); // Default staking duration is 1 month
  const [stakingAmount, setStakingAmount] = useState('1'); 

  const handleStake = () => {
    // Handle staking based on the selected staking duration
    switch (stakingDuration) {
      case '0':
        handleStaking(0);
        break;
      case '1':
        handleStaking(1);
        break;
      case '2':
        handleStaking(2);
        break;
      case '3':
        handleStaking(3);
        break;
      default:
        break;
    }
  };
  async function isApproved() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractMain = new ethers.Contract(
        MainAddress,
        abi,
        readerProvider
      );
      try {
        balance = await readContractMain.isApprovedForAll(address, StakingAddress);
        return balance;
      } catch (error) {
        console.log("Error checking eligibility:", error);
      }

    }
  }

  async function approve() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0
      const contract = new ethers.Contract(
        MainAddress,
        abi,
        provider.getSigner()
      );
      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractMain = new ethers.Contract(
        MainAddress,
        abi,
        readerProvider
      );
      try {
        await contract.setApprovalForAll(StakingAddress, true);
      } catch (error) {
        return false;
      }
    }
  }

  async function handleStaking(lock) {
    await HandleNetworkSwitch();

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const contract = new ethers.Contract(
        StakingAddress,
        StakingABI,
        provider.getSigner()
      );
      // Check the signer's balance
      const address = await provider.getSigner().getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractMain = new ethers.Contract(
        MainAddress,
        abi,
        readerProvider
      );
      if (!(await isApproved())) {
        console.log(isApproved())
        approve()
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "Approving. Try again after approving!";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

        return;
      }
      if ((await getBalance(address)) < 1
      ) {
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "You dont have any tokens to stake!";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

        return;
      }
      if (canStakeAgain == true) {
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "Please wait until the previous transaction is confirmed!";

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

        return;
      }
      try {
        const response = await contract.stake(await collectTokenIds(address, stakingAmount), lock);
        console.log("response: ", response);
        // create the notification element
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        setCanStakeAgain(true)

        // set the notification text
        notification.innerText = "Staking Transaction sent!";
        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);
        await response.wait();

        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        notification.innerText = "You have successfully staked!";

        setCanStakeAgain(false)

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

      } catch (err) {
        console.log("error: ", err);
        setCanStakeAgain(false)

        // create the notification element
        var notification = document.createElement("div");
        notification.style.position = "fixed";
        notification.style.top = "10px";
        notification.style.right = "55px";
        notification.style.padding = "10px";
        notification.style.backgroundColor = "#c7ab55";
        notification.style.color = "black";
        notification.style.borderRadius = "5px";
        notification.style.fontFamily = "Cinzel Decorative";
        notification.style.marginTop = "5%";
        notification.style.fontWeight = "bold";
        notification.classList.add("notification-animation");
        notification.style.marginLeft = "8%";

        document.body.appendChild(notification);

        // create the close button
        var closeButton = document.createElement("button");
        closeButton.style.float = "right";
        closeButton.innerText = "x";
        notification.appendChild(closeButton);

        // set the notification text
        if (err.message) {
          notification.innerText = "FAIL!";
        } else {
          notification.innerText = "FAIL!";
        }

        // add a click event listener to the close button
        closeButton.addEventListener("click", function () {
          notification.style.display = "none";
        });
        setTimeout(function () {
          notification.style.display = "none";
        }, 3000);

      }
    }
  }

  async function getBalanceStaked() {

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0;

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractStaked = new ethers.Contract(
        StakingAddress,
        StakingABI,
        readerProvider
      );
      try {
        balance = await readContractStaked.balanceOf(address.toLowerCase());
        setStakedBalance(balance);
        return balance;
      } catch (error) {
        console.error("Error checking eligibility:", error);
        setStakedBalance(0);
        return 0;
      }

    }
  }
  async function getBalanceStakedFor1Month() {

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0;

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractStaked = new ethers.Contract(
        StakingAddress,
        StakingABI,
        readerProvider
      );
      try {
        let tokens = await readContractStaked.tokensOfOwner(address.toString().toLowerCase());
        console.log("tokens to be checked: ", tokens)
        let balance = [];
        for (let tokenId of tokens) {
          let lockType = await readContractStaked.getLockOfToken(parseInt(tokenId._hex, 16).toString());
          if (lockType == 0) {
            balance.push(tokenId);
          }
        }

        setStakedBalance1Month(balance.length);
        return balance;
      } catch (error) {
        console.error("Error checking eligibility:", error);
        setStakedBalance1Month(0);
        return 0;
      }

    }
  }
  async function getBalanceStakedFor3Months() {

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0;

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractStaked = new ethers.Contract(
        StakingAddress,
        StakingABI,
        readerProvider
      );
      try {
        let tokens = await readContractStaked.tokensOfOwner(address.toString().toLowerCase());
        console.log("tokens to be checked: ", tokens)
        let balance = [];
        for (let tokenId of tokens) {
          let lockType = await readContractStaked.getLockOfToken(parseInt(tokenId._hex, 16).toString());
          if (lockType == 1) {
            balance.push(tokenId);
          }
        }

        setStakedBalance3Months(balance.length);
        return balance;
      } catch (error) {
        console.error("Error checking eligibility:", error);
        setStakedBalance3Months(0);
        return 0;
      }

    }
  }
  async function getBalanceStakedFor1Year() {

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0;

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractStaked = new ethers.Contract(
        StakingAddress,
        StakingABI,
        readerProvider
      );
      try {
        let tokens = await readContractStaked.tokensOfOwner(address.toString().toLowerCase());
        console.log("tokens to be checked: ", tokens)
        let balance = [];
        for (let tokenId of tokens) {
          let lockType = await readContractStaked.getLockOfToken(parseInt(tokenId._hex, 16).toString());
          if (lockType == 2) {
            balance.push(tokenId);
          }
        }

        setStakedBalance1Year(balance.length);
        return balance;
      } catch (error) {
        console.error("Error checking eligibility:", error);
        setStakedBalance1Year(0);
        return 0;
      }

    }
  }
  async function getBalanceStakedFor3Years() {

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0;

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractStaked = new ethers.Contract(
        StakingAddress,
        StakingABI,
        readerProvider
      );
      try {
        let tokens = await readContractStaked.tokensOfOwner(address.toString().toLowerCase());
        console.log("tokens to be checked: ", tokens)
        let balance = [];
        for (let tokenId of tokens) {
          let lockType = await readContractStaked.getLockOfToken(parseInt(tokenId._hex, 16).toString());
          if (lockType == 3) {
            balance.push(tokenId);
          }
        }

        setStakedBalance3Years(balance.length);
        return balance;
      } catch (error) {
        console.error("Error checking eligibility:", error);
        setStakedBalance3Years(0);
        return 0;
      }


    }
  }
  async function getBalance() {

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Check the signer's balance
      let balance = 0

      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      // mainnet
      // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
      const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

      const readContractMain = new ethers.Contract(
        MainAddress,
        abi,
        readerProvider
      );
      try {
        balance = await readContractMain.balanceOf(address.toLowerCase());
        setBalance(balance);
        return balance;
      } catch (error) {
        console.error("Error checking eligibility:", error);
        setBalance(0);
        return 0;
      }

    }
  }
  // Function to collect NFT Token IDs for a specific contract
  async function collectTokenIds(address, stakingAmount) {
    // const readerProvider = new ethers.providers.JsonRpcProvider(`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
    // mainnet
    // const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);
    const readerProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`);

    const readContractMain = new ethers.Contract(
      StakingAddress,
      StakingABI,
      readerProvider
    );
    let tokenIds = [];
    tokenIds = await readContractMain.tokensOfOwnerUnStaked(address.toString().toLowerCase())
    console.log("tokenIds that you own: ", tokenIds)
    console.log("tokens that you are going to stake: ", tokenIds.slice(0, stakingAmount))
    return tokenIds.slice(0, stakingAmount);
  }

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    getBalanceStaked();
  }, []);

  useEffect(() => {
    getBalanceStakedFor1Year();
  }, []);
  useEffect(() => {
    getBalanceStakedFor1Month();
  }, []);
  useEffect(() => {
    getBalanceStakedFor3Months();
  }, []);
  useEffect(() => {
    getBalanceStakedFor3Years();
  }, []);

  return (
    <>
      <NavBarMint accounts={accounts} setAccounts={setAccounts} />
      <Container
        as="main"
        maxW={"1700px"}
        centerContent
        mx={"auto"}
        mt={{ base: "1rem", lg: "6rem" }}
      >
        <Image
          src={ColLogo}
          align="center"
          boxSize={{ base: "300px", md: "400px" }}
        />
        <Flex justify="space-between" gap={"0.55rem"}
        >
        
          <Box
            color={"#e7e5e4"}
            className="font-cinzel"
            bgColor={"#0b0d12"}
            px={{ base: "1.5rem", md: "2.5rem" }}
            py={{ base: "0.75rem", md: "1rem" }}
            rounded={"1rem"}
            my={"1.5rem"}
            border={"1px solid #c7ab55"}
          >
            <Text
              fontWeight={"bold"}
              fontSize={{ base: "1.5rem", md: "2rem" }}
              lineHeight={"2rem"}
              my={"0.2rem"}
            >
              Stake Tokens
            </Text>
            <div>
              <label htmlFor="staking-duration" className="font-cinzel" style={{ fontSize: '1.5rem' }}>
              </label>
              <input
                type="number"
                id="staking-duration"
                value={stakingAmount}
                onChange={(e) => setStakingAmount(e.target.value)}
                className="font-cinzel"
                min="0"
                max="3"
                step="0"
                style={{
                  backgroundColor: '#0b0d12', // Set the background color
                  fontSize: '1.5rem', // Increase the font size
                  height: '3rem', // Adjust the height of the select element
                  outline: 'none', // Remove the outline
                  color: '#e7e5e4', // Set the text color
                  padding: '0.75rem 1.5rem', // Set the padding for base size
                  borderRadius: '1rem', // Set the border radius
                  border: '1px solid #c7ab55', // Set the border
                }}
              />
              <button
                onClick={() => {
          
                  setStakingAmount(Number(balance).toFixed(0));
                }}
                style={{
                  marginLeft: '0.5rem', // Add some left margin to separate the button from the input field
                }}
              >
                Max
              </button>
            </div>


            <Heading
              fontSize={{ md: "1.7rem" }}
              className="font-cinzel"
              my={"0.3rem"}
            >
              Choose your Staking Duration:
            </Heading>

            {/* Staking Duration Selection */}
            <div>
              <label htmlFor="staking-duration" className="font-cinzel" style={{ fontSize: '1.5rem' }}>
              </label>
              <select
                id="staking-duration"
                value={stakingDuration}
                onChange={(e) => setStakingDuration(e.target.value)}
                className="font-cinzel"

                style={{
                  backgroundColor: '#0b0d12', // Set the background color
                  fontSize: '1.5rem', // Increase the font size
                  height: '3rem', // Adjust the height of the select element
                  outline: 'none' // Remove the outline

                }}
              >
                <option value="0">3 Month</option>
                <option value="1">6 Months</option>
                <option value="2">1 Year</option>
                <option value="3">3 Years</option>
              </select>
            </div>

            {isConnected ? (
              <Button
                type="button"
                className="font-cinzel"
                style={{ backgroundColor: "#c7ab55", color: "#000", marginTop: "0.5rem" }}
                onClick={() => handleStake(stakingDuration)}
                fontSize={{ base: "1.5rem", md: "2rem" }}
                fontWeight={"bold"}
                px={"1.5rem"}
                py={"0.25rem"}
                rounded={"1rem"}
                gap={"0.25rem"}
              >
                <SwordsIcon color="#000" />
                Stake
              </Button>

            ) : (
              <Button
                type="button"
                className="font-cinzel"
                style={{ backgroundColor: "#c7ab55", color: "#000", marginTop: "0.5rem" }}
                fontSize={{ base: "1.5rem", md: "2rem" }}
                fontWeight={"bold"}
                px={"1.5rem"}
                py={"0.25rem"}
                rounded={"1rem"}
                gap={"0.25rem"}
                disabled={!isConnected}
              >
                <SwordsIcon color="#000" />
                Stake
              </Button>
            )}
          </Box>

        </Flex>

        <Flex justify="center" align="top">
          {isConnected ? (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
              {Number(stakedBalance).toFixed(0)} staked | {Number(balance).toFixed(0)} unstaked<br />
              {Number(stakedBalance1Year).toFixed(0)} staked - 1 Year |  {Number(stakedBalance3Years).toFixed(0)} staked - 3 Years <br />
              {Number(stakedBalance1Month).toFixed(0)} staked - 3 Months | {Number(stakedBalance3Months).toFixed(0)} staked - 6 Months <br />
              <br />

            </Text>
          ) : (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
              Connect To Proceed
            </Text>
          )}
        </Flex>

      </Container>
    </>
  );
};
export default MintPage;


async function HandleNetworkSwitch() {

  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");

    if (window.ethereum.networkVersion !== 1) {

      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x1" }]
        });

      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

const networks = {
  homestead: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://api.mycryptoapi.com/eth/"],
    blockExplorerUrls: ["https://etherscan.io/"]
  }
};
/**
 * 
 * 
 *        <Image 
        src="path/to/allowlisted-image.jpg"
        isHidden={!isAllowlisted(accounts[0])}
      />
MAINNET: 
async function HandleNetworkSwitch() {

    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 1) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x1" }]
                });

            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['homestead']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    homestead: {
        chainId: `0x${Number(1).toString(16)}`,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://api.mycryptoapi.com/eth/"],
        blockExplorerUrls: ["https://etherscan.io/"]
    }
};
SEPOLIA:
async function HandleNetworkSwitch() {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== "11155111") {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0xAB13" }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['sepolia']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    sepolia: {
        chainId: `0x${Number(11155111).toString(16)}`,
        chainName: "Sepolia",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://sepolia.infura.io/v3/"],
        blockExplorerUrls: ["https://sepolia.etherscan.io"]
    }
};


GÖRLI:
async function HandleNetworkSwitch() {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 5) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x5" }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['goerli']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    goerli: {
        chainId: `0x${Number(5).toString(16)}`,
        chainName: "Goerli",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://goerli.infura.io/v3/YOUR-PROJECT-ID"],
        blockExplorerUrls: ["https://goerli.etherscan.io/"]
    }
};

 */